import React from 'react';

const HeaderText = () => {
  return <div>
      <h1>Our Homepage</h1>
      <hr />
      </div>;
};

export default HeaderText;
